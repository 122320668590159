.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    background: linear-gradient(rgba(0, 0, 0, 0.6) 100%, rgba(0, 0, 0, 0.6) 100%),
        url("../../assets/img/landing-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    padding: 15rem 7.2rem;

    &__types {
        margin-top: 12rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 4rem;
        justify-content: center;
    }
    &__type {
        flex: 1;
        color: white;
        width: 100%;
        height: 18rem;
        background-color: #000;
        cursor: pointer;
        display: flex;
        max-width: 40rem;
        align-items: center;
        justify-content: center;
        min-width: 30rem;
        border-radius: 1rem;
        font-size: 2rem;
        font-weight: 700;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        transition: all 0.2s ease-out;
        &:nth-of-type(1) {
            background-image: linear-gradient(rgba(0, 0, 0, 0.3) 100%, rgba(0, 0, 0, 0.3) 100%),
                url("../../assets//img/handgun-bg.jpg");
            &:hover {
                background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, rgba(0, 0, 0, 0.2) 100%),
                    url("../../assets//img/handgun-bg.jpg");
            }
        }
        &:nth-of-type(2) {
            background-image: linear-gradient(rgba(0, 0, 0, 0.3) 100%, rgba(0, 0, 0, 0.3) 100%),
                url("../../assets//img/shotgun-bg.jpg");
            &:hover {
                background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, rgba(0, 0, 0, 0.2) 100%),
                    url("../../assets//img/shotgun-bg.jpg");
            }
        }
        &:nth-of-type(3) {
            background-image: linear-gradient(rgba(0, 0, 0, 0.3) 100%, rgba(0, 0, 0, 0.3) 100%),
                url("../../assets//img/rifle-bg.jpg");
            &:hover {
                background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, rgba(0, 0, 0, 0.2) 100%),
                    url("../../assets//img/rifle-bg.jpg");
            }
        }
        &:nth-of-type(4) {
            background-image: linear-gradient(rgba(0, 0, 0, 0.3) 100%, rgba(0, 0, 0, 0.3) 100%),
                url("../../assets//img/rimfire-bg.jpg");
            &:hover {
                background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, rgba(0, 0, 0, 0.2) 100%),
                    url("../../assets//img/rimfire-bg.jpg");
            }
        }
        &:hover {
            transform: scale((1.04));
        }
    }
}

@media only screen and (max-width: 800px) {
    .home__type {
        max-width: 50rem;
        min-width: none;
        height: 24rem;
    }
}

@media only screen and (max-width: 500px) {
    .home {
        padding: 12rem 4.2rem;
        &__types {
            margin-top: 8rem;
        }
        &__type {
            height: 19rem;
        }
    }
}
