.browseComponents {
    display: flex;
    flex-direction: column;
    color: #000;
    &__title {
        align-self: center;
        padding: 4rem 0;
        font-size: 2.8rem;
        font-weight: 600;
    }
    &__container {
        display: flex;
        gap: 2rem;
        padding: 3rem;
        border-radius: 1rem;
    }
    &__filter {
        display: flex;
        flex-direction: column;
        padding: 3rem 2rem;
        background: white;
        width: 30rem;
        max-width: 30rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        border-radius: 1rem;
        &-title {
            font-size: 2rem;
            font-weight: 500;
            margin-bottom: 4rem;
        }

        form {
            display: flex;
            flex-direction: column;
            margin-bottom: 3rem;
            gap: 1.6rem;
            label {
                display: flex;
                flex-direction: column;
                gap: 0.8rem;
                font-size: 1.6rem;
                font-weight: 500;
                select {
                    outline: none;
                    border: none;
                    border: 2px solid #000;
                    padding: 0.8rem;
                    height: 5.5rem;
                    font-size: 1.6rem;
                    font-weight: 600;
                    appearance: none;
                    background-repeat: no-repeat;
                    background-image: url("../../assets/img/arrow.svg");
                    background-position: calc(100% - 21px) 50%;
                }
                input {
                    outline: none;
                    border: none;
                    border: 2px solid #000;
                    padding: 0.8rem;
                    height: 5.5rem;
                    font-size: 1.6rem;
                    font-weight: 600;
                    appearance: none;
                }
            }
        }

        &-btn {
            display: flex;
            padding: 1.4rem 2.4rem;
            width: fit-content;
            border: none;
            outline: none;
            align-self: center;
            background-color: black;
            color: white;
            font-size: 1.6rem;
            font-weight: 500;
            border: 2px solid #000;
            transition: all 0.3s ease-in-out;
            &:hover {
                background: transparent;
                color: black;
            }
        }
    }
    &__content {
        display: flex;
        width: 100%;
        background: #fff;
        padding: 3rem 0rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        flex-direction: column;
        border-radius: 1rem;
        &-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 2rem;
            padding-bottom: 1rem;
            border-bottom: 2px solid #000;
            gap: 1.5rem;
            span {
                font-size: 1.5rem;
                font-weight: 600;
                flex: 1;
                &:nth-of-type(1) {
                    flex: 2;
                }
            }
        }
        .pagination {
            margin-bottom: 3rem;
        }
        &-container {
            display: flex;
            flex-direction: column;
            gap: 3rem;
            animation: 0.5s appear;
        }
        &-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1.5rem;
            padding: 1.5rem 2rem;
            cursor: pointer;

            &.odd {
                background-color: #eee;
            }
            span {
                font-size: 1.3rem;
                font-weight: 400;
                flex: 1;
                &:nth-of-type(1) {
                    flex: 2;
                }
            }
        }
    }
    &__product-container {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        padding: 2rem;
        gap: 1rem;
        border-bottom: 2px solid rgba(40, 40, 40);
        color: black;
        section {
            display: flex;
            align-items: center;
            gap: 1.5rem;
            span {
                font-size: 1.6rem;
                font-weight: 600;
                &:nth-of-type(2) {
                    font-size: 1.8rem;
                }

                a {
                    padding: 0.8rem 1.2rem;
                    color: white;
                    background: black;
                    cursor: pointer;
                    text-decoration: none;
                    border-radius: 0.6rem;
                    font-size: 1.3rem;
                }
            }
        }
        div {
            display: flex;
            align-items: center;
            gap: 1.5rem;
            p {
                flex: 1;
                font-size: 1.5rem;
                font-weight: 500;
                color: rgb(112, 112, 112);
                display: flex;
                flex-direction: column;
                gap: 0.8rem;
                span {
                    color: rgb(0, 0, 0);
                }
            }
        }
    }
}

.pagination {
    text-align: center;
    align-self: center;
    transform: scale(1.5);
    --bs-pagination-active-bg: #000 !important;
    --bs-pagination-active-border-color: #000 !important;
    --bs-pagination-color: #000 !important;
    --bs-pagination-hover-color: #000 !important;
}

@keyframes appear {
    0% {
        display: none;
        transform: scale(0);
    }
    99% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
        display: flex;
    }
}

@media only screen and (max-width: 1200px) {
    .browseComponents {
        &__container {
            flex-direction: column;
        }
        &__filter {
            flex-direction: column;
            width: 100%;
            max-width: none;
            &-title {
                margin-bottom: 3rem;
            }
            form {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-bottom: 3rem;
                justify-content: center;
                gap: 1.6rem;
                label {
                    width: 100%;
                    max-width: 35rem;
                    min-width: 15rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .browseComponents {
        &__content {
            &-title {
                padding: 0 1rem;
                padding-bottom: 1rem;
                gap: 1rem;
                span {
                    font-size: 1.3rem;
                }
            }
            &-box {
                gap: 1rem;
                padding: 1.5rem 1rem;
                cursor: pointer;
                span {
                    font-size: 1.1rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 740px) {
    .browseComponents {
        form {
            flex-wrap: nowrap;
            flex-direction: column;
            label {
                max-width: none;
                min-width: none;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .browseComponents {
        &__content {
            overflow: auto;
            &-title {
                width: 80rem;
            }
            &-box {
                width: 80rem;
            }
        }
    }
    .pagination {
        margin-top: 3rem;
    }
}
