.header {
    width: 100vw;
    background-color: rgb(40, 40, 40);
    padding: 1.5rem 3rem;
    height: 100%;
    &__logo {
        font-size: 2rem;
        color: white;
        text-decoration: none;
        cursor: pointer;
    }
    ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        list-style: none;
    }
    &__links {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex: 1;
        gap: 2rem;
        margin-right: 80px;
        a {
            font-size: 1.4rem;
            color: #ddd;
            text-decoration: none;
            transition: all 0.2s ease-out;
            &:hover {
                color: #eee;
            }
            &.active {
                color: white;
            }
        }
    }
    p{
        font-size: 1.4rem;
        margin-right: 1.2rem;
        text-decoration: underline;
        text-transform: capitalize;

    }
    &__login {
        margin-left: auto;
        background: none;
        cursor: pointer;
        padding: 5px 10px;
        border: 1px solid #ddd;
        color: #ddd;
        font-family: "Montserrat", sans-serif;
        transition: all 0.2s ease-out;
        &:hover {
            border: 1px solid #eee;
            color: #eee;
            border-radius: 5px;
        }
        &.logout{
            background-color: rgb(255, 71, 71);
            color: white;
            border: none;
        }
    }
}

.modal {
    padding: 2.4rem 3.6rem !important;
    &__title {
        font-size: 3.2rem;
        font-weight: 600;
    }
    form {
        display: flex;
        margin-top: 2.4rem;
        flex-direction: column;
        gap: 1.6rem;
        input {
            width: 450px;
            padding: 8px 16px;
            outline: none;
        }
        button {
            margin-left: auto;
            background: none;
            cursor: pointer;
            padding: 5px 10px;
            border: 1px solid #ddd;
            color: #ddd;
            font-family: "Montserrat", sans-serif;
            transition: all 0.2s ease-out;
            font-size: 1.8rem;
            &:hover {
                border: 1px solid #eee;
                color: #eee;
                border-radius: 5px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .modal {
        width: calc(100% - 72px);
        form {
            input {
                width: 100%;
            }
        }
    }
}

.MuiAlert-message {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
}
