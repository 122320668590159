.product {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__title {
        text-align: center;
        padding: 6rem 7.2rem;
        background-color: #000;
        font-size: 2.6rem;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.6rem;
        span {
            font-size: 2.4rem;
            font-weight: 500;
        }
    }
    &__container {
        display: flex;
        flex-direction: column;
        margin: 0 7.2rem;
        margin-top: 3.6rem;
        display: flex;
        background: #fff;
        padding: 3rem 0rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        flex-direction: column;
        border-radius: 1rem;
        &-title {
            font-size: 2.2rem;
            font-weight: 500;
            padding-bottom: 0.8rem;
            padding-left: 1.6rem;
            color: #000;
            border-bottom: 1px solid #000;
        }
    }
    &__content {
        &-title {
            display: flex;
            align-items: center;
            margin-top: 1.6rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgb(194, 194, 194);
            padding-left: 1.6rem;
            padding-right: 1.6rem;
            span {
                font-size: 1.4rem;
                font-weight: 400;
                color: black;
                flex: 1;
                &.promo {
                    flex: 2;
                }
            }
        }
        &-container {
            display: flex;
            flex-direction: column;
            // gap: 3rem;
        }
        &-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1.5rem;
            padding: 1.5rem 1.6rem;
            cursor: pointer;
            &.odd {
                background-color: #eee;
            }
            span {
                font-size: 1.5rem;
                font-weight: 500;
                flex: 1;
                color: #000;
                &.promo {
                    flex: 2;
                }
                a {
                    padding: 0.8rem 1.2rem;
                    color: white;
                    background: black;
                    cursor: pointer;
                    text-decoration: none;
                    border-radius: 0.6rem;
                    font-size: 1.3rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .product {
        &__container {
            margin-left: 2.4rem;
            margin-right: 2.4rem;
        }
    }
}

@media only screen and (max-width: 1050px) {
    .product {
        &__content {
            &-title {
                gap: 1rem;
                padding-right: 1rem;
                padding-left: 1rem;
                span {
                    font-size: 1.2rem;
                }
            }
            &-container {
                display: flex;
                flex-direction: column;
                // gap: 3rem;
            }
            &-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 1rem;
                padding: 1.5rem 1rem;
                cursor: pointer;
                &.odd {
                    background-color: #eee;
                }
                span {
                    font-size: 1.2rem;
                    a {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .product {
        &__title {
            font-size: 2.4rem;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1.6rem;
            span {
                font-size: 2.2rem;
                font-weight: 500;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .product {
        &__container {
            overflow: auto;
            &-title {
                width: 80rem;
            }
        }
        &__content {
            &-title {
                width: 80rem;
            }
            &-container {
                width: 80rem;
            }
        }
    }
}
